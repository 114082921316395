import $ from 'jquery';

$(function () {
  $('#appointmentslogin').on('submit', function (event) {
    event.preventDefault();
    $('#login-alert').hide();
    
    if (!$(this)[0].checkValidity()) return;

    $.ajax({
      url: $(this).attr('action'),
      type: $(this).attr('method'),
      // dataType: "JSON",
      data: new FormData(this),
      processData: false,
      contentType: false,
      success: function (data, status) {
        $('.page-loading').hide();
        if (data.success) {
          console.log(data)
          window.location.href = data.href;
        }
        else {
          $('#login-alert').html(data.message);
          $('#login-alert').show();
        }
      },
      error: function (xhr, desc, err) {
        $('#login-alert').html(xhr.responseText);
        $('#login-alert').show();
        $('.page-loading').hide();
      }
    });
  });
});