import $ from 'jquery';
import Mapper from '../wrappers/mapper';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-nl';
import moment from 'moment';
import Dropzone from 'dropzone';

$(function () {
  $('.afspraak-maken.make-quickrepair').on('click', function (event) {
    event.preventDefault();
    $('#qrtext').hide(0, function() {
      $('.quick-repair-container').show('fast', function () {
        console.log($(this).offset().top)
        $('html,body').animate({
          scrollTop: $(this).offset().top - $('.header').outerHeight()
        })
      });
    });
  });

  let map;

  $('#quickreppair-form input[name="services[]"]').on('change', function () {
    let ids = [];

    $('#quickreppair-form input[name="services[]"]:checked').each(function (k, el) {
      ids.push($(el).val());
    });

    if (ids.length > 0) $('.qr-select-errors').hide();

    localStorage.setItem('services', ids.join(','));
  });

  $('#showServiceLocations').on('click', function () {
    if ($('input[name="services[]"]:checked').length == 0) {
      $('.qr-select-errors').show().trigger('selectErrorShown');
      return;
    }

    let button = $(this);
    button.addClass('loading');

    $.get(`${publicApiRoutes.quickRepairLocations}?services=${localStorage.getItem('services')}`)
      .done(function (response) {
        $('.location-list').html(response);
      })
      .fail(function (error) {

      })
      .always(function() {
        button.removeClass('loading');
      });
  });

  $('#quickrepair-step1-submit').on('click', function () {
    let form = $(this).parents('form');
    if ($('input[name="services[]"]:checked').length == 0) {
      $('.qr-select-errors').show().trigger('selectErrorShown');
      return
    }
    $('.qr-select-errors').hide();
    form.find('.step1').hide();
    form.find('.step2').show(0, qrStep2Shown());
  });


  function qrStep2Shown() {
    map = new Mapper('quickrepair-map', false);
    map.getLocations('', localStorage.getItem('services'));
  }

  $('#quickrepair-step2-addressInput').on('keyup', function (event) {
    if (event.key !== 'Enter') return;
    event.preventDefault();
    $(this).siblings('#quickrepair-step2-search-vestiging').trigger('click');
  })

  $('#quickrepair-step2-search-vestiging').on('click', function (event) {
    event.preventDefault();
    let input = $(this).siblings('input#quickrepair-step2-addressInput');
    let address = input.val();

    if (address !== '') {
      localStorage.setItem('user-address', $(this).val());
      map.getLocations(address, localStorage.getItem('services'));
    } else {
      input.addClass('is-invalid');
    }
  });

  $(document).on('click', '#quickreppair-form .select_location, .select-locations-quick-repair', function (event) {
    event.preventDefault();

    if ($('input[name="services[]"]:checked').length == 0 || $('input[name="services[]"]:checked').length >= 2) {
      let phone = $(this).attr('data-phone');
      $('.qr-select-errors').show().trigger('selectErrorShown', phone);
      return;
    }

    let form = $('#quickreppair-form');
    form.find('input#quickrepair-location-id').val($(this).attr('id'));

    if($('html,body').scrollTop() !== form.offset().top - $('.header').outerHeight()) {
      $('html,body').scrollTop(form.offset().top - $('.header').outerHeight())
    }

    $.get(`/quick-repair/time/${$(this).attr('id')}/${localStorage.getItem('services')}`)
      .done(function (appointmentTime) {
        localStorage.setItem('qr_appointment_time', JSON.stringify(appointmentTime));
        form.find('.step2, .step1').hide();
        form.find('.step3').show(0, qrStep3Shown());
        $('.location-list').html('');
      })
      .fail(function (error) {
        console.log(error)
      });
  });

  function qrStep3Shown() {
    let form = $('#quickreppair-form');
    let appointmentTime = JSON.parse(localStorage.getItem('qr_appointment_time'));
    form.find('#quickrepair-service-id').val(localStorage.getItem('services'));
    form.find('.progress-bar').animate({
      width: '66.666%',
    });

    $('#quickrepair-date').datepicker({
      dateFormat: 'dd-mm-yy',
      firstDay: 1,
      beforeShowDay: function (date) {
        const mDate = moment(date);

        if (
          appointmentTime.holidays.indexOf(mDate.format('YYYY-MM-DD')) >= 0 ||
          appointmentTime.quickrepair_time[mDate.format('dddd').toLowerCase()]['open'] !== '1' ||
          moment().subtract(1, 'days').valueOf() >= mDate.valueOf()
        ) return [false, 'disabled'];

        return [true, ""];
      },
      onSelect: function (date) {
        $('.availability-error-msg').hide();
        const mDate = moment(date, 'DD-MM-YYYY');
        const today = moment().startOf('day');
        const now = moment();
        const interval = appointmentTime.interval;
        const workTime = appointmentTime.work_time[mDate.format('dddd').toLowerCase()];
        const appTime = appointmentTime.quickrepair_time[mDate.format('dddd').toLowerCase()];

        $.get(`/quick-repair/check-appointment?location_id=${$('input#quickrepair-location-id').val()}&service=${localStorage.getItem('services')}&date=${date}`)
          .done(function (takenTimes) {
            console.log(takenTimes)
            let options = '';
            console.log(appTime)
            if (appTime !== undefined) {
              console.log(parseInt(workTime.open_at))
              for (let i = parseInt(workTime.open_at); i < parseInt(workTime.close_at);) {
                console.log(i)
                let time = moment(i.toString().formatTime(), 'HH:mm');
                let breakStart = moment(workTime.break_start.formatTime(), 'HH:mm');
                let breakEnd = moment(workTime.break_end.formatTime(), 'HH:mm');

                if ((today.valueOf() !== mDate.valueOf() || i > (parseInt(now.hours() + '' + now.minutes()) + 60)) && !time.isBetween(breakStart, breakEnd) && takenTimes.indexOf(time.format('HH:mm')) == -1) {
                  options += `<option value="${i}">${time.format('HH:mm')}</option>`;
                }

                i = parseInt(time.add(interval, 'minutes').format('HHmm'));
              }
            }
            $('select#quickrepair-time').html(options).removeAttr('disabled');
          })
          .fail(function (error) {
            console.log(error)
            $('.availability-error-msg').show()
          });
      }
    });

  }

  $('#quickreppair-form').on('submit', function (event) {
    event.preventDefault();
    if (!this.checkValidity()) return;

    const form = $(this);

    $.ajax({
      url: form.attr('action'),
      type: $(this).attr("method"),
      dataType: "JSON",
      data: new FormData(this),
      processData: false,
      contentType: false,
      beforeSend: function () {
        form.find('button[type="submit"]').addClass('loading');
      },
      success: function (response) {
        window.location = '/verstuurd';
      },
      error: function (response) {

        Object.keys(response.responseJSON.errors).forEach(function (input_name) {
          let errors = response.responseJSON.errors[input_name];

          errors.forEach(function (error, key) {
            $('<div></div>', {
              class: 'alert alert-danger',
              html: error
            }).appendTo($('.form-errors'));
          });

        });
      },
      complete: function () {
        form.find('button[type="submit"]').removeClass('loading');
      }
    });

  });



  $('#quickrepair-date').on('blur focus', function(event) {
    if(event.type === 'focus') {
      $(this).attr('readonly', true);
    }

    if(event.type === 'blur') {
      $(this).removeAttr('readonly');
    }
  });

  if ($('#qr_file').length > 0) {
    const qrDropzone = new Dropzone('div#qr_file', {
      paramName: 'file',
      url: publicApiRoutes.qrFileUpload,
      params: {
        _token: sessionToken
      },
      success: function (file, response) {
        $("#quickreppair-form").append($('<input type="hidden" ' +
          'name="images[]" ' +
          'originalname="' + response.originalname + '"' +
          'value="' + response.filename + '">'));

        $('.form-errors').hide();
      },
      dictDefaultMessage: "Klik hier om een foto toe te voegen",
      clickable: true,
      addRemoveLinks: true,
      maxFiles: 3,
      acceptedFiles: 'image/*',
      init: function () {
        this.on("maxfilesexceeded", function (file) {
          alert('U kunt niet meer dan 3 afbeeldingen uploaden');
          $(file.previewElement).remove();
        });
      },
      removedfile: function (file) {
        if (file.accepted) {
          let filename = $("#quickreppair-form").find('input[originalname="' + file.name + '"]').val();
          $.ajax({
            method: "DELETE",
            url: publicApiRoutes.deleteNotSavedFile,
            data: { _token: sessionToken, file_name: filename }
          })
            .done(function (response) {
              let type = response.success ? 'success' : 'danger';
              if (response.success == true) {
                $("#quickreppair-form").find('input[originalname="' + file.name + '"]').remove();
                console.log($(file.previewElement))
                $(file.previewElement).remove();
              }
            });
        } else {
          file.previewElement !== null ? document.removeChild(file.previewElement) : void 0;
        }
      }
    });
  }

  $('.step3 .btn-left-arrow ').on('click', function () {
    console.log('stap terug')
    $('.step3').hide();
    $('.step2').show(0, function () {
      if (map === undefined) {
        map = new Mapper('quickrepair-map', false);
        map.getLocations('', localStorage.getItem('services'));
      }
    })
  });

  $(document).on('click', '.pagination a', function(event) {
    console.log('pagination clicked')
    if ($('.location-list').length) {
      event.preventDefault();
      $.get(`${$(this).attr('href')}&services=${localStorage.getItem('services')}`)
      .done(function(response) {
        $('.location-list').html(response);
        $('html,body').animate({
          scrollTop: $('.location-list').offset().top - $('.header').outerHeight()
        })
      })
      .fail(function(error) {
        console.log(error);
      });
    }
  });

  $('.qr-select-errors').on('selectErrorShown', function(event, number) {
    $(this).find('div').hide();

    if($('input[name="services[]"]:checked').length == 0 ) {
      $(this).find('.selected-to-few').show()
    }

    if($('input[name="services[]"]:checked').length > 1) {
      $(this).find('.selected-to-many').show()
      $(this).find('.selected-to-many .error-number-wrapper').html(`<a href="tel:${number}">${number}</a>`)
    }
  });
});