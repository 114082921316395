import $ from 'jquery';
class Mapper {

  constructor(
    mapId = 'map',
    locate = true,
    lat = 52.301907,
    lng = 4.770477,
    zoom = 9,
    mapType = 'roadmap',
    styles = []
  ) {
    // do stuff
    this.mapContainer = document.getElementById(mapId);
    this.latitude = lat;
    this.longitude = lng;
    this.directionService = new google.maps.DirectionsService;
    this.directionDisplay = new google.maps.DirectionsRenderer;
    this.zoom = zoom;
    this.mapType = mapType;
    this.styles = styles;
    this.markers = [];

    
    if (locate) {
      this.geoWatchId = null;
      this.getGeolocation();
    }
    if (this.mapContainer !== null) {
      this.map = this.initMap();

      this.directionDisplay.setMap(this.map);

      this.infoWindow = new google.maps.InfoWindow();
    }
  }

  initMap() {
    return new google.maps.Map(this.mapContainer, {
      center: {
        lat: this.latitude,
        lng: this.longitude
      },
      zoom: this.zoom,
      mapTypeId: this.mapType,
      styles: this.styles.length > 0 ? this.styles : this.getDefaultStyles()
    });
  }

  getLocations(
    address = '',
    services = '',
    limit = 10,
    diameter = 50,
    leasingcompany = '',
    fromAppointment = false,
    insurancecompany = ''
  ) {
    this.destroyMarkers();

    if (address !== '') {
      this.getSearchLocation(address);
    }

    fromAppointment = $(this.mapContainer).parents('form').length > 0;

    fetch(`/vestigingen/widgetdata?keyword=${address}&services=${services}&limit=${limit}&diameter=${diameter}&leasingcompany=${leasingcompany}&from_appointment=${fromAppointment}&insurancecompanies=${insurancecompany}`)
      .then(response => response.text())
      .then(xmlStr => {
        this.handleXmlResponse(xmlStr)
      })
      .catch(error => {
        console.log(error);
      })
  }

  createMarker(latlng, name, address1, address2, url, selecteren, phone) {
    var map = this;
    var marker = new google.maps.Marker({
      map: this.map,
      position: latlng,
      icon: '/images/asn_marker.png'
    });

    this.name = name;
    this.address1 = address1;
    this.address2 = address2;
    this.url = url;
    this.selecteren = selecteren;

    google.maps.event.addListener(marker, 'click', function () {
      localStorage.setItem('destination', marker.getPosition().lat() + ', ' + marker.getPosition().lng());
      let userAddr = localStorage.getItem('user-address');
      if (userAddr !== null && userAddr !== '') {
        let routeRequest = {
          origin: userAddr,
          destination: `${address1} ${address2}`,
          travelMode: 'DRIVING'
        }
        map.directionService.route(routeRequest, (result, status) => {
          if(status === 'OK') {
            map.directionDisplay.setDirections(result)
          }
        })
      }

      var html = `<div class="infowindow">
                    <div class="title"><img src="/images/widget_info_logo.png"><a target="_parent" href="${url}"><strong>${name}</strong></a></div>
                    <div class="addr">${address1}</div>
                    <div class="addr-2">${address2}</div>
                    <div class="action"><button type="button" id="${selecteren}" data-name="${name}" data-phone="${phone}" class="select_location btn btn-gold btn-sm">${selectmessage}</button></div>
                  </div>`;

      localStorage.setItem('destination', marker.getPosition().lat() + ', ' + marker.getPosition().lng());

      map.infoWindow.setContent(html);
      map.infoWindow.open(map, marker);
    });

    this.markers.push(marker);
  }

  setNewCoords(lat, lng, zoom = 8) {
    this.latitude = lat;
    this.longitude = lng;
    this.zoom = zoom;
    if (this.map !== null && this.map !== undefined) {
      this.map.setCenter({
        lat: this.latitude,
        lng: this.longitude
      });

      this.map.setZoom(this.zoom)
    }
  }

  getGeolocation() {
    if (!navigator.geolocation) return;

    navigator.geolocation.getCurrentPosition(
      position => {
        this.setNewCoords(position.coords.latitude, position.coords.longitude, 10);
      },
      error => {
        // console.log(error)
      }
    );

    this.geoWatchId = navigator.geolocation.watchPosition(
      position => {
        this.setNewCoords(position.coords.latitude, position.coords.longitude, 10);
      },
      error => {
        // console.log(error);
      }
    );
  }

  clearGeoWatch() {
    if(this.geoWatchId === null || this.geoWatchId === undefined) return;

    navigator.geolocation.clearWatch(this.geoWatchId);
  }

  getSearchLocation(addr) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ region: 'nl', address: addr }, results => {
      if (results.length > 0) {
        const result = results[0];

        this.setNewCoords(result.geometry.location.lat(), result.geometry.location.lng(), 9);
        this.createUserLocationMarker(result.geometry.location.lat(), result.geometry.location.lng());
      }
    })
  }

  createUserLocationMarker(lat, lng) {
    if (this.searchMarker !== undefined) {
      this.searchMarker.setMap(null);
    }

    this.searchMarker = new google.maps.Marker({
      map: this.map,
      position: { lat: lat, lng: lng },
      icon: '/images/asn_marker_search.png',
      clickable: false
    });
    
  }

  getSingleLocation(id) {
    const _this = this;
    $.ajax({
      method: 'GET',
      url: '/vestigingen/widgetdata?location=' + id,
      success: function (xmlStr) {
        _this.handleXmlResponse(xmlStr)
      }
    })
  }

  getInsurerLocations(url) {
    const _this = this;
    $.ajax({
      method: 'GET',
      url: `${url}/widgetdata`,
      success: function (xmlStr) {
        _this.handleXmlResponse(xmlStr)
      }
    })
  }

  getCalamityLocation() {
    const _this = this;
    $.ajax({
      method: 'GET',
      url: '/calamities/get-location',
      success: function(response) {
        const location = response.location
        console.log(location)
        _this.setNewCoords(parseFloat(location.latitude), parseFloat(location.longitude), 10);
        _this.createMarker(
          {lat: parseFloat(location.latitude), lng: parseFloat(location.longitude)},
          location.title.nl,
          location.address,
          location.postcode + ' ' + location.city,
          location.slug.nl,
          location.id,
          location.phone
        );
      }
    })
  }

  handleXmlResponse(xmlStr) {
    const _this = this;
    const xml = $.parseXML(xmlStr);
    const markerNodes = xml.documentElement.getElementsByTagName("marker");
    for (var i = 0; i < markerNodes.length; i++) {
      var name = markerNodes[i].getAttribute("name");
      var phone = markerNodes[i].getAttribute("phone");
      var url = markerNodes[i].getAttribute("url");
      var address1 = markerNodes[i].getAttribute("address1");
      var address2 = markerNodes[i].getAttribute("address2");
      var distance = parseFloat(markerNodes[i].getAttribute("distance"));
      var selecteren = parseFloat(markerNodes[i].getAttribute("selecteren"));
      var latlng = new google.maps.LatLng(
        parseFloat(markerNodes[i].getAttribute("lat")),
        parseFloat(markerNodes[i].getAttribute("lng"))
      );

      _this.createMarker(latlng, name, address1, address2, url, selecteren, phone);
    }
  }

  destroyMarkers() {
    while (this.markers.length) {
      this.markers.pop().setMap(null);
    }
  }

  getDefaultStyles() {
    return [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f5f5"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#bdbdbd"
          }
        ]
      },
      {
        "featureType": "administrative.neighborhood",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#757575"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dadada"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#616161"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      },
      {
        "featureType": "transit",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e5e5e5"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#eeeeee"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#c9c9c9"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9e9e9e"
          }
        ]
      }
    ]
  }

}

export default Mapper;