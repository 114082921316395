import $ from 'jquery';
import Isotope from 'isotope-layout';

$(function () {
  const container = document.querySelector('.isotope');
  if(null !== container) {
    const iso = new Isotope( container, {
      itemSelector: '.isotope-item',
    });
    
    $('.load-more-reviews').on('click', function(){
      let page = $('.isotope-item').length / 10;
      let button = $(this);
      console.log(`sending request to: ${window.location.pathname}?page=${page}`)
      $.ajax({
        method: 'GET',
        url: `${window.location.pathname}?page=${page}`,
        beforeSend: function() {
          button.addClass('loading');
        },
        success: function(reactions) {
          console.log(reactions)
          var reactionsHtml = $(reactions);
          $('.isotope > .isotope-items-wrap').append(reactionsHtml);
          iso.appended(reactionsHtml);
          iso.layout();
        },
        complete: function() {
          button.removeClass('loading')
        }
      });
    });
  }
});