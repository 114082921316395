import $ from "jquery";
import CookieLegitNotice from "./cookielegit";
import LegitCookies from "./cookies";

$(function($) {
  CookieLegitNotice.subscribe("consent-given", (consentData) => {
    const consentedTo = consentData.consentedTo.map(cookie => `${cookie.name.replace('cl_', '')}=${(cookie.value === "true")}`).join('&')
    $.get(`/cookie-stats?${consentedTo}&update=${!consentData.firstConsent}`);
  });

  new CookieLegitNotice("body", {
    userOpt: true,
    consentMode: true,
  });
});
