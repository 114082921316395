import $ from 'jquery';
require('jquery-inview');

$(function () {
  $('.load-locations').on('inview', function (event, isInView) {
    if (!isInView) return;
    $(this).trigger('click');
  });

  $('.load-locations').on('click', function () {
    let button = $(this);
    let page = ($('.autoshade_box').length / 10) + 1;
    
    if($('.autoshade_box').length == $('.locations-items-wrapper').attr('data-location-count')) {
      $('.load-locations').remove();
      return;
    }

    $.ajax({
      method: 'GET',
      url: `${window.location.href}?page=${page}&limit=10`,
      beforeSend: function () {
        button.addClass('loading')
      },
      success: function (response) {
        $('.locations-items-wrapper').append(response);
      },
      complete: function() {
        button.removeClass('loading')
      }
    })

  });
});
