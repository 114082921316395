import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-nl';
import moment from 'moment';
import Mapper from '../wrappers/mapper';
import 'dropzone';
import Dropzone from 'dropzone';

// js for the appointment form
Dropzone.autoDiscover = false;

$(() => {

  const weekdays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ];

  let map;

  $('input[name="appointment_type"]').on('change', function() {
    const val = $(this).val();

    $('.form-group.particulier > div').hide();

    if (val === "Schade inspectie") {
      $('.company-search-wrapper').show();
    } else if (val === 'Inname leaseauto') {
      $('#select-lease').show();
    } else {
      // noting to show
    }

    // Checking if appointments aren't blocked
    let start = moment($(this).attr('data-start'), "DD MMM YYYY ");
    let end = moment($(this).attr('data-end'), "DD MMM YYYY");

    if (moment().isBetween(start, end)) {
      $('#disabled-text').removeClass('alert-warning').addClass('alert-danger');
      $('#step1-submit').attr('disabled', true);
    } else {
      $('#disabled-text').addClass('alert-warning').removeClass('alert-danger');
      $('#step1-submit').removeAttr('disabled')
    }

    $('#disabled-text').removeClass('d-flex').addClass('hidden');

    if ($(this).attr('data-showtext') == '1') {
      let text = $(this).attr('data-text').replace(':start', start.format('DD MMM YYYY')).replace(':end', end.format('DD MMM YYYY'));
      $('#disabled-text .text').text(text);
      $('#disabled-text').addClass('d-flex').removeClass('hidden');
    }

  });

  $('.company-search').on('keyup', function() {
    let query = $(this).val();
    let resultContainer = $(this).siblings('.company-results');
    resultContainer.html('');
    $(this).removeClass('is-invalid')

    if (query === '') {
      resultContainer.hide();
      return;
    }

    $.ajax({
      method: "GET",
      url: `${publicApiRoutes.companies}?q=${query}`,
      success: response => {
        if (response.companies.length > 0) {
          resultContainer.show();
        }

        resultContainer.html('');

        $.each(response.companies, (k, v) => {
          if (v.type === 'company') {
            $('<div></div>', {
              html: v.title,
              class: 'company-result',
              attr: {
                'data-title': v.title
              }
            }).appendTo(resultContainer);
          }
        });
      },
      error: error => {
        console.log(error);
      }
    })
  });

  $(document).on('click', '.company-result', function() {
    $(this).parents('.form-group').children('input').val($(this).attr('data-title'));
    $(this).parents('.company-results').hide().html('');
  });

  $('#step1-submit').on('click', function() {
    const appointmentType = $(this).parents('form').find('input[name="appointment_type"]:checked').val();
    const leaseCompanyInput = $('.form-control.company-search');

    localStorage.setItem('appointment_type', appointmentType);

    if (appointmentType === "Schade inspectie" && leaseCompanyInput.val() === '') {
      leaseCompanyInput.addClass('is-invalid');
      return;
    }
    if (localStorage.getItem('location_id') == undefined) {
      $('.step1').hide();
      $('.step2').show(0, () => {
        map = new Mapper('appointment-map', false);
        let address = localStorage.getItem('user-address') !== null ? localStorage.getItem('user-address') : '';
        map.getLocations(address);
        setTimeout(() => {
          $('.progress-bar').animate({
            width: '33.333%'
          })
        }, 200);
      });
    } else {
      let locationId = localStorage.getItem('location_id');
      $.get(`${publicApiRoutes.locationAppointmentTime}/${locationId}`)
        .done(response => {
          localStorage.setItem('appointmenttime', JSON.stringify(response));
          $('.step2, .step1').hide();
          $('.step3').show(0, shownStep3());
        })
        .fail(error => {
          alert('Er is iets mis, probeer het opnieuw');
        });
    }
  });

  $('#step2-addressInput').on('keydown', function(event) {
    if (event.key !== "Enter") return;

    event.preventDefault();

    if ($(this).val() !== '') {
      localStorage.setItem('user-address', $(this).val());
      map.getLocations($(this).val());
    } else {
      $(this).addClass('is-invalid');
    }
  });

  $('#step2-search-vestiging').on('click', function(event) {
    event.preventDefault();
    let input = $(this).siblings('input#step2-addressInput');
    let address = input.val();

    if (address !== '') {
      localStorage.setItem('user-address', input.val());
      map.getLocations(address);
    } else {
      input.addClass('is-invalid');
    }
  });

  $('.step2-nav-buttons button').on('click', function() {
    $('.step2').hide();
    $('.step1').show(0, () => {
      $('.progress-bar').animate({
        width: '0%'
      })
    });
  });

  $(document).on('click', '#appointmentForm .select_location', function(event) {
    event.preventDefault();
    const locationId = $(this).attr('id');
    localStorage.setItem('location_id', locationId);
    $('#locationId').val(locationId);

    $.get(`${publicApiRoutes.locationAppointmentTime}/${locationId}`)
      .done(response => {
        localStorage.setItem('appointmenttime', JSON.stringify(response));
        $('.step2').hide();
        $('.step3').show(0, shownStep3());
      })
      .fail(error => {
        alert('Er is iets mis, probeer het opnieuw of');
      });
  });

  function shownStep3() {
    $('.progress-bar').animate({
      width: '66.666%',
    });

    let appointmentTime = JSON.parse(localStorage.getItem('appointmenttime'));

    if ($('#locationId').val() == '') $('#locationId').val(localStorage.getItem('location_id'));

    $('#date.form-control').datepicker({
      dateFormat: 'dd-mm-yy',
      firstDay: 1,
      beforeShow: function(input, instance) {
        var modal = $(input).parents('#appointment-popup');
        setTimeout(() => {
          $(instance.dpDiv).css({
            top: (($(input).offset().top + $(input).outerHeight()) - modal.offset().top) + 'px',
            left: $(input).offset().left + 'px'
          });
        }, 200);
      },
      beforeShowDay: function(date) {
        const mDate = moment(date);

        if (
          appointmentTime.block !== null &&
          appointmentTime.block.period !== 'none' &&
          mDate.isBefore(moment().add(appointmentTime.block.amount, appointmentTime.block.period))
        ) {
          return [false, 'disabled'];
        }

        if (localStorage.getItem('location_id') != '') {
          var locationId = parseInt(localStorage.getItem('location_id'));
          var appointmentType = localStorage.getItem('appointment_type');

          // @temp: check vermaire amstelveen 3
          if (locationId === 3 && appointmentType === 'Inname leaseauto' && new Date() < new Date('2024-10-14')) {
            var enumerateDaysBetweenDates = function(startDate, endDate) {
              var dates = [];
              var currDate = moment(startDate).startOf('day');
              var lastDate = moment(endDate).startOf('day');
          
              while(currDate.add(1, 'days').diff(lastDate) < 0) {
                  dates.push(currDate.clone().format('YYYY-MM-DD'));
              }
          
              return dates;
            };

            appointmentTime.holidays = appointmentTime.holidays.concat(enumerateDaysBetweenDates('2024-09-15', '2024-10-12'))
            appointmentTime.holidays = appointmentTime.holidays.filter((value, index, array) => array.indexOf(value) === index);
          }
        }

        if (
          appointmentTime.holidays.indexOf(mDate.format('YYYY-MM-DD')) >= 0 ||
          appointmentTime.work_time[mDate.format('dddd').toLowerCase()]['open'] !== '1' ||
          moment().valueOf() >= mDate.valueOf()
        ) {
          return [false, 'disabled'];
        }

        if (
          $('input[name="appointment_type"]:checked').attr('data-start') !== undefined &&
          $('input[name="appointment_type"]:checked').attr('data-end') !== undefined
        ) {
          let startDate = moment($('input[name="appointment_type"]:checked').attr('data-start'), 'DD MMM YYYY');
          let endDate = moment($('input[name="appointment_type"]:checked').attr('data-end'), 'DD MMM YYYY');
          if (mDate.isSameOrAfter(startDate) && mDate.isSameOrBefore(endDate)) {
            return [false, 'disabled']
          }
        }

        return [true, ""];
      },
      onSelect: function(date, element) {
        console.log(date);
        $('.availability-error-msg').hide();
        const mDate = moment(date, 'DD-MM-YYYY');
        const tomorrow = moment().add(1, 'day').startOf('day');
        const now = moment();
        const interval = 20;
        const workTime = appointmentTime.work_time[mDate.format('dddd').toLowerCase()];
        const appTime = appointmentTime.appointmenttime[mDate.format('dddd').toLowerCase()];

        const dayTime = {
          'Monday': {
            min: 1000,
            max: 1600
          },
          'Tuesday': {
            min: 800,
            max: 1600
          },
          'Wednesday': {
            min: 800,
            max: 1600
          },
          'Thursday': {
            min: 800,
            max: 1600
          },
          'Friday': {
            min: 800,
            max: 1500
          },
          'Saturday': {
            min: 0,
            max: 2400
          },
          'Friday': {
            min: 0,
            max: 2400
          }
        };

        const dayConstraint = dayTime[mDate.format('dddd')];
        const appointmentType = localStorage.getItem('appointment_type');
        $.get('/location-appointment/check-appointment', { date: date, location_id: localStorage.getItem('location_id'), appointment_type: appointmentType })
          .done(function(takenTimes) {
            let options = '';
            if (appTime == undefined) {
              for (let i = parseInt(workTime.open_at); i < parseInt(workTime.close_at);) {
                let time = moment(i.toString().formatTime(), 'HH:mm');
                let breakStart = moment(workTime.break_start.formatTime(), 'HH:mm');
                let breakEnd = moment(workTime.break_end.formatTime(), 'HH:mm');
                let notFull = takenTimes['times'][time.format('HH:mm:ss')] == undefined || takenTimes[time.format('HH:mm:ss')] < 2;

                if (appointmentType === 'Inname leaseauto' && 'Inname leaseauto' in takenTimes['appointment_types']) {
                  // notFull = takenTimes['appointment_types']['Inname leaseauto'] < 2;
                }

                if (
                  (
                    tomorrow.valueOf() !== mDate.valueOf() ||
                    time.diff(now, 'minutes') >= 0
                  ) &&
                  !time.isBetween(breakStart, breakEnd) &&
                  notFull &&
                  (i >= dayConstraint.min && i <= dayConstraint.max)
                ) {
                  options += `<option value="${i}">${time.format('HH:mm')}</option>`;
                }

                i = parseInt(time.add(interval, 'minutes').format('HHmm'));
              }
            } else {
              for (let times = 0; times < appTime.start_at.length; times++) {
                let start_at = parseInt(appTime.start_at[times]);
                let end_at = parseInt(appTime.end_at[times]);

                for (let i = start_at; i < end_at;) {
                  let time = moment(i.toString().formatTime(), 'HH:mm');
                  let notFull = takenTimes['times'][time.format('HH:mm:ss')] == undefined || takenTimes['times'][time.format('HH:mm:ss')] < 2;
                  if (appointmentType === 'Inname leaseauto' && 'Inname leaseauto' in takenTimes['appointment_types']) {
                    notFull = takenTimes['appointment_types']['Inname leaseauto'] < 2;
                  }


                  if ((tomorrow.valueOf() !== mDate.valueOf() || time.diff(now, 'minutes') >= 0) && notFull && (i >= dayConstraint.min && i <= dayConstraint.max)) {
                    options += `<option value="${i}">${time.format('HH:mm')}</option>`;
                  }

                  i = parseInt(time.add(interval, 'minutes').format('HHmm'));
                }
              }
            }

            if (options.length === 0) {
              options = '<option>Geen tijden beschikbaar</option>'
            }

            $('select#time').html(options).removeAttr('disabled');
          })
          .fail(function(error) {
            $('.availability-error-msg').show()
          });
      }
    });

  }

  $('#date.form-control').on('blur focus', function(event) {
    if (event.type === 'focus') {
      $(this).attr('readonly', true);
    }

    if (event.type === 'blur') {
      $(this).removeAttr('readonly');
    }
  });

  $('.submit-appointment').on('click', function() {
    $('.progress-bar').animate({
      width: '100%',
    });
  });

  $('.step3-nav-buttons .btn-left-arrow ').on('click', function(event) {
    event.preventDefault();
    $('.step2').show();
    $('.step3').hide(0, () => {
      if (map === undefined) {
        map = new Mapper('appointment-map', false);
        map.getLocations();
      }

      // need to unset datepicker
      $('#date.form-control').datepicker('destroy');
      $('#date.form-control').val('');
      $('.woring-time.form-select').find('option').remove();

    });
  });

  $('#appointmentForm .btn-close').on('click', function() {
    const popup = $(this).parents('.popup');
    popup.animate({
      opacity: 0
    }, {
      duration: 200,
      complete: function() {
        popup.css('display', 'none');
      }
    });
    $(this).parents('form')[0].reset();

  });

  $('#appointmentForm').on('submit', function(e) {
    e.preventDefault();
    $('.availability-error-msg').hide();
    if (!$(this)[0].checkValidity()) return;

    $(this).find('button[type="submit"]').addClass('loading');

    const form = $(this);

    if (form.find('#time').val() === '' || form.find('#time').val() === null) {
      let locationInfo = localStorage.getItem('appointmenttime') !== null ? JSON.parse(localStorage.getItem('appointmenttime')) : { title: 'de directie', phone: '085 - 044 53 53' }
      $('.availability-error-msg').find('span').html(`Er is een probleem met uw inzending, ververs de pagina en probeer het opnieuw. Of neem direct contact op met ${locationInfo.title} door te bellen op <a href="tel:${locationInfo.phone}">${locationInfo.phone}</a>`);
      $('.availability-error-msg').show();
      $(this).find('button[type="submit"]').removeClass('loading')
      return;
    }

    var url = $(this).attr("action");
    $.ajax({
      url: url,
      type: $(this).attr("method"),
      dataType: "JSON",
      data: new FormData(this),
      processData: false,
      contentType: false,
      beforeSend: function() {
        $('.request-validation-error').remove()
      },
      success: function(data, status) {
        if (data.success) {
          localStorage.removeItem('user-address');
          localStorage.removeItem('location_id');
          localStorage.removeItem('appointmenttime');

          window.location.href = '/verstuurd';
        } else {
          alert('Er is iets mis, probeer het opnieuw')
        }
      },
      error: function(xhr, desc, err) {
        if (xhr.responseJSON.errors) {
          Object.values(xhr.responseJSON.errors).forEach(function(errors) {
            $('.availability-error-msg span').append('<p class="request-validation-error" style="margin-bottom:5px;">' + errors.join('. ') + '.</p>')
          });
        }

        $('.availability-error-msg').show();
      },
      complete: function() {
        form.find('button[type="submit"]').removeClass('loading')
      }
    });
  });

  if ($('#app_file').length > 0) {
    const dropzone = new Dropzone("div#app_file", {
      paramName: "file",
      url: publicApiRoutes.appointmentFileUpload,
      params: {
        _token: sessionToken
      },
      dictDefaultMessage: "Klik hier om een foto toe te voegen",
      clickable: true,
      addRemoveLinks: true,
      maxFiles: 3,
      acceptedFiles: 'image/*',
      init: function() {
        this.on("maxfilesexceeded", function(file) {
          alert('U kunt niet meer dan 3 afbeeldingen uploaden');
          $(file.previewElement).remove();
        });
      },
      success: function(file, response) {
        $("#appointmentForm").append($('<input type="hidden" id="qrimages"' +
          'name="images[]" ' +
          'originalname="' + response.originalname + '"' +
          'value="' + response.filename + '">'));
      },
      removedfile: function(file) {
        if (file.accepted) {
          let filename = $("#appointmentForm").find('input[originalname="' + file.name + '"]').val();
          $.ajax({
            method: "DELETE",
            url: publicApiRoutes.appointmentFileDelete,
            data: { _token: sessionToken, file_name: filename },
            success: response => {
              $("#appointmentForm").find('input[originalname="' + file.name + '"]').remove();
              $(file.previewElement).remove();
            },
            error: err => {
              console.log(err)
            }
          });
        } else {
          file.previewElement !== null ? document.removeChild(file.previewElement) : void 0;
        }
      }

    });
  }

  $(document).on('click', '.btn-location-appointment', function(event) {
    localStorage.setItem('location_id', $(this).attr('data-lid'));
  });

  $('#appointment-popup').on('popupClosed', function() {
    localStorage.removeItem('user-address');
    localStorage.removeItem('location_id');
    localStorage.removeItem('appointmenttime');

    let form = $(this).find('form');
    form[0].reset();
    form.find('.step2, .step3').hide();
    form.find('.step1').show();
    form.find('input[name="appointment_type"]:checked').trigger('change')
    form.find('.progress-bar').css('width', 0);
    form.find('#time.form-select').html('<option class="disabled">Selecteer een tijd</option>').attr('disabled', true);
    form.find('#date.form-control').datepicker('destroy');
  });

});

String.prototype.formatTime = function() {
  return (this.length === 4 ? this : '0' + this).replace(/(\d{2})(\d{2})/, '$1:$2');
}
