import $ from 'jquery';
import Mapper from './wrappers/mapper';
import 'bootstrap';

$(function () {
  const homeMap = new Mapper('home-map');
  homeMap.getLocations();

  $(document).on('click', '*[data-toggle="popup"]', function (event) {
    event.preventDefault();
    let modal = $($(this).attr('data-target'));

    if(modal.find('.close-popup').length === 0) {
      modal.append('<div class="close-popup"></div>');
    }

    modal.css('display', 'flex');
    modal.animate({
      'opacity': 1
    }, {
      duration: 200,
      complete: () => {
        modal.trigger('popupOpened')
      }
    })
    $('body, html').addClass('popup-open');

  });

  $(document).on('click', '.close-popup', function () {
    let modal = $(this).parents('.popup');

    modal.animate({
      'opacity': 0
    }, {
      duration: 200,
      complete: () => {
        modal.css('display', 'none');
        modal.trigger('popupClosed');
      }
    })
    $('body, html').removeClass('popup-open');
  });


  var cookie_msg_box = $('.cc-window');
  $(cookie_msg_box).append('<img class="toggle-cookie-text" src="/icons/expand_less_black_24dp.svg" alt="Expand" />')
  $(document).on('click', '.toggle-cookie-text', function () {
    $(this).toggleClass('open');
    $(this).parents('.cc-window').toggleClass('cc-auto-height');
  });

  let isTyping = null;
  $('.nav_search input[type="text"]').on('keyup', function () {
    const input = $(this);

    if (isTyping !== null) clearTimeout(isTyping);

    isTyping = setTimeout(function () {
      if (input.val().length > 0) {
        $.ajax({
          url: `/autocomplete?query=${input.val()}&limit=10`,
          method: 'get',
          beforeSend: function() {
            input.siblings('.result-container').remove();
          },
          success: function (response) {
            let container = $('<div class="result-container"></div>')
            console.log(response)
            $.each(response, function(k, v) {
              let slug = (v.indexable_type.includes('Page') ? publicApiRoutes.page : publicApiRoutes.location)
                          .replace(':slug', v.indexable.slug.nl ?? '');

              $('<a></a>', {
                href: slug,
                text: v.indexable.title.nl
              }).appendTo(container);
            })

            container.appendTo(input.parents('form'));
          }
        })
      } else {
        input.siblings('.result-container').remove();
      }
    }, 300)
  });

  $('.nav-icon').on('click', function() {
		$('body').toggleClass('open');
		$('.nav-icon').toggleClass('open');
	});

  if($('.video-container').find('h1,h2,h3,h4,h5').length >= 2) {
    let height = 0;

    $('.video-container').find('h1,h2,h3,h4,h5').each(function(){
      if($(this).height() > height) {
        height = $(this).height();
      }
    });

    $('.video-container').find('h1,h2,h3,h4,h5').height(height);
  }

  if($('body').hasClass('body-vacatures-index')) {
    // $.each($('.vacancy-title'), function() {
    //   $(this).parent('.overlay').height($(this).outerHeight());
    // })
  }

});

require('./scripts/appointment');
require('./scripts/bs-form-validation');
require('./scripts/berijder-login');
require('./scripts/locations-infinite-scroll');
require('./scripts/location');
require('./scripts/reactions');
require('./scripts/insurance-companies');
require('./scripts/field-validators');
require('./scripts/quick-repair');
require('./scripts/cookiesbanner');